import React from "react";
import { graphql } from "gatsby";
import { Box } from "@chakra-ui/react";
import Header from "../components/header";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}: {
  data: any;
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Box className="relative overflow-clip bg-gray-100 w-full flex flex-col p-[16px_16px_0px] box-border items-center justify-center text-left text-2xl text-gray-800 font-helvetica-neue">
      <Header contactUs={() => {}} />
      <style>
        {`
        h1 {
            font-size: 1.5em;
        }

        h2 {
            font-size: 1.3em;
        }

        p {
            padding-top: 10px;
        }`}
      </style>
      <Box
        my="48px"
        p="16px"
        w="80%"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Box>
  );
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
      }
    }
  }
`;
